.photo-upload-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .photo-upload-item {
    width: 300px;
    background-color: #EEEEEE;
    border-radius: 5px;
    padding: 10px;

    .private-image-placeholder {
      width: 300px;
      height: 400px;
      margin: auto;
    }

    .private-image {
      display: block;
      width: 300px;
      height: 400px;
      object-fit: contain;
      margin-left: auto;
      margin-right: auto;
    }

    .photo-moderation-info {
      p {
        text-align: center;
        font-weight: bold;
      }

      p.photo-moderation-status {
        text-transform: capitalize;
      }

      .photo-moderation-buttons {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        gap: 20px;
      }

    }
  }
}