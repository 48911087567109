.snack-list {
  margin-top: 30px;
  padding: 20px;

  h2 {
    margin: 0px 0px 25px 0px;
  }

  .snack-list-item {
    margin-bottom: 15px;
    padding: 10px 20px;

    .snack-list-item-info {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
    }

    .snack-list-item-controls {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .snack-list-item-header {

      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

  }

  .snack-editor {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px;
    align-items: center;
  }

  .snack-image {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #004373;
    color: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.editable {
      cursor: pointer;
    }
  }
}