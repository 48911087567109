.screen-list-item {
  margin: 10px 0px;

  .screen-list-item-header {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .screen-list-item-title {
      display: flex;
      align-items: center;

      >p {
        margin-right: 10px;
      }

      >div {
        display: flex;
      }
    }

  }

  .screen-list-item-details {

    .screen-component {
      margin: 10px;
    }

    .add-component-controls {
      background-color: #f2f2f2;
      padding: 10px;

      >button {
        margin-right: 10px;
      }
    }
  }
}