.journey-list {
  padding: 0px 20px 10px 20px;

  .journey-list-item {
    padding: 15px;
    margin: 10px 0px;

    .journey-list-item-contents {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .journey-list-item-actions {
        display: flex;
        align-items: center;

        .copy-button-wrapper {
          margin-right: 20px;
        }
      }
    }
  }

  .journey-list-controls {
    padding: 10px 0px;
    display: flex;
    justify-content: flex-start;
  }
}


.journey-navigation {
  display: flex;
  justify-content: center;

  margin: 20px auto 20px auto;
}

.journey-detail {
  .journey-detail-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .journey-details-editor {
    padding: 20px;

    h2 {
      margin: 0px;
    }

    .journey-details-editor-info,
    .journey-details-editor-editing {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 20px;
      align-items: center;
    }
  }
}