.question-dialog.dialog-content {
  width: 700px;

  .settings-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 80px;
  }

  .basic-settings {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 5px;


    .points {
      width: 100px;
    }

    .incorrect-deduction-points {
      width: 180px;
    }

    .hint-reminder {
      width: 150px;
    }

    .max-answers {
      width: 100px;
    }

    .levenshtein {
      width: 100px;
    }

    .use-sounds,
    .use-confetti {
      display: flex;
      flex-direction: row;
      align-items: center;
      //Below: Copied from material ui textfield
      background-color: rgba(0, 0, 0, 0.09);
      padding: 3px 3px 3px 6px;
      border-radius: 3px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    }
  }

  .answer {
    margin-bottom: 10px;

    .add-answer-button {
      margin-top: 10px;
    }
  }

  .hint-list {

    .hint-list-item-wrapper {
      margin-bottom: 5px;

      flex-direction: column;
      display: flex;

      .hint-list-item {
        margin-bottom: 10px;

        >div {
          margin-right: 5px;
        }

        .hint-textfield {
          width: 200px;
        }

        .hint-number {
          width: 100px;
        }
      }
    }
  }

  .answer-list {
    .answer-list-item-wrapper {
      margin-bottom: 5px;
    }
  }
}