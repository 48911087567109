
.media-dialog.dialog-content {
  width: auto;  

  h2 {
    text-align: center;
  }

  .media-manager {
    display: flex;
    justify-content: space-between;

    .media-list-wrapper {
      margin-bottom: 10px;
      width: 75%;
  
      .media-list {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
  
        .media-item {
          display: flex;
          justify-content: center;
          border: 1px solid #AAAAAA;
          border-radius: 5px;
          width: 145px;
          height: 145px;
          padding: 10px;

          &.selected {
            border: 2px solid #555555;
            padding: 9px;
          }
          
          .media-item-content {
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            width: 100%;
            height: 100%;
          }
        }
      }
  
      .media-list-controls {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 25px;
        padding: 5px;
        background-color: #f2f2f2;
      }
    }

    .media-edit-form {
      background-color: #f2f2f2;
      border-radius: 5px;
      width: 20%;
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      .media-form-image {
        width: 100%;
        height: auto;
      }
    }
  }  
}