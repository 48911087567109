.snack-screen-creator {
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;

  .snack-screen-creator-input {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .snack-screen-type-picker {
      width: 150px;
    }
  }
}

.snack-screen-item {
  background-color: #f5f5f5;
  padding: 2px 15px;
  border-radius: 4px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .snack-screen-item-info {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

  }
}

.snack-screen-dialog.dialog-content {
  width: 900px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;

  .snack-screen-settings {
    width: 480px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    h2 {
      text-align: start;
      margin: 0px;
    }

    .snack-screen-basic {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 10px;

      .form-textfield {
        width: 355px;
        margin: 0px;
        margin-top: 10px;
      }

      .form-textfield.duration {
        width: 115px;
      }
    }

    .snack-screen-text {
      height: 190px;
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: flex-start;

      .snack-screen-text-settings {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .language-picker {
          width: 120px;
        }

        .form-textfield {
          width: 120px;
          margin: 0px;
        }
      }
    }

    .snack-screen-color {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: flex-start;

    }
  }

  .snack-screen-preview-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .snack-screen-preview {
      width: 300px;
      height: 596px;
      background-size: contain;
      background-image: url(./iphone_preview.png);
      background-repeat: no-repeat;

      .snack-screen-preview-background {
        width: 269px;
        height: 570px;
        margin: 13px 15px 14px 15px;
        position: relative;

        .snack-screen-preview-background-color {
          width: 100%;
          height: 100%;
          border-radius: 40px;
        }

        .snack-screen-preview-background-image {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          border-radius: 40px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }

        .snack-screen-preview-background-video {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          border-radius: 40px;
        }

        .snack-screen-preview-content-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          top: 0px;
          left: 0px;
          position: absolute;
          text-align: center;
          font-weight: 500;

          .snack-screen-preview-content {
            margin: 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .snack-screen-preview-content-text {
              padding: 8px 5px;
              border-radius: 6px;

              p {
                margin: 0px;
              }
            }

            .snack-screen-preview-content-component {
              padding: 8px 5px;
              border-radius: 6px;

              margin-top: 10px;
              padding: 5px;
              background-color: #DDDDDD;
            }
          }


        }
      }
    }
  }
}

.screen-component-manager-controls {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;

}