@import './animations.scss';
@import './fonts.scss';
@import '../features/user/assets/user.scss';
@import '../features/customer/assets/customer.scss';
@import '../features/challenge/assets/challenge.scss';
@import '../features/event/assets/event.scss';
@import '../features/screen/assets/screens.scss';
@import '../features/screen_component/assets/screen_component.scss';
@import '../features/team/assets/team.scss';
@import '../features/question/assets/question.scss';
@import '../features/journey/assets/journey.scss';
@import '../features/snack/assets/snack.scss';
@import '../features/snack_screen/assets/snack_screen.scss';
@import '../features/media/assets/media.scss';
@import '../features/event_update/assets/event_update.scss';
@import '../features/photo_upload/assets/photo_uploads.scss';
@import '../features/photo_upload/assets/photo_uploads.scss';
@import '../features/share/assets/share_challenge.scss';

.app {
  min-height: 100vh;
  background-color: #e8e8e8;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1E1E1E;
}

.page {
  width: 120vw;
  height: 100vw;
  display: flex;
  flex-grow: 1;
}

.page-header {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  margin: 0px 0px 10px 0px;

  .page-title-wrapper {
    display: flex;
    align-items: center;

    .page-title {
      margin-left: 10px;

      h1 {
        margin: 0px;
        text-align: left;
        font-size: 40px;
      }

      .environment-indicator {
        color: blue;
        display: block;
      }
    }
  }
}

.page-detail {
  padding: 25px;
  margin: 10px 0 10px 0;
}

.logo {
  margin: 10px;
  width: 100px;
  height: 100px;
  background-size: contain;
  background-image: url(./images/metis_united_logo.png);
  background-repeat: no-repeat;

  &.large {
    width: 150px;
    height: 150px;
  }
}

.dialog-content {
  width: 300px;

  h2 {
    text-align: center;
    margin-bottom: 10px;
  }

  .error {
    width: 100%;
    text-align: center;
  }

  .form-textfield {
    margin: 10px 0px;
  }

  .form-image {
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
      width: 100px;
    }
  }

  .form-language-picker {
    margin: 10px 0px;

    >p {
      margin: 0px;
    }
  }

  .form-color-picker {
    margin: 10px auto 20px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    >.form-color-picker-color {
      border-radius: 10px;
      width: 90px;
      height: 200px;
    }
  }
}

.text-content {
  color: #1D1D1E;
}

.error {
  font-size: 12px;
  color: DarkRed;
}

body .language-picker {
  min-width: 100px;
}

.date-time-picker {
  >label {
    font-size: 15px;
    display: block;
    margin-bottom: 10px;
  }
}

.color-picker {
  .form-textfield {
    width: 235px;
  }

  .react-colorful {
    width: 235px;
  }
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 17px;
}