.event-update-list {
  padding: 5px 10px 0px 10px;

  table {
    width: 100%;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }

  th {
    text-align: left;
    padding: 15px 0px 15px 5px;

    position: -webkit-sticky; // this is for all Safari (Desktop & iOS), not for Chrome
    position: sticky;
    top: 0;
    z-index: 1; // any positive value, layer order is global
    background: #fff; // any bg-color to overlap
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  td {
    padding: 5px;
    font-size: 0.9em;

    ul {
      margin: 0px;
    }

    p {
      margin: 0px;
    }
  }

  .event-update-controls {
    margin-top: 30px;
  }

  /*  .event-update-item {
    background-color: #f4f4f4;
    padding: 5px 5px 5px 5px;
    margin: 10px 0px 10px 0px;

    p {
      margin: 0px;
      font-size: 0.90em;
    }
    
  }
  */

}