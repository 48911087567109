.share-challenge-view {
  width: 100%;
  height: 100%;

  p,
  h1,
  h2,
  h3,
  h4 {
    margin: 0px
  }

  .wrapper {
    width: 1024px;
    margin: auto;
    display: flex; //If this is set, margins of internal components are contained within this container 
    flex-direction: column;
    align-items: center;
    gap: 30px;

    >div {
      width: 100%;
    }

    h2.section-title {
      color: white;
      font-size: 40px;
      font-family: 'Gagalin';
      text-align: center;
    }

    .header {
      background-color: white;
      border-radius: 0px 0px 40px 40px;
      padding: 35px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 50px;

      .customer-logo {
        max-height: 100px;
      }

      h1 {
        font-size: 40px;
        font-family: 'Gagalin';
      }
    }

    .team-results {
      display: flex;
      flex-direction: column;
      gap: 30px;
      align-items: center;

      .team-result {
        width: 400px;
        padding: 20px;
        background-color: white;
        border-radius: 20px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); //https://codepen.io/sdthornton/pen/wBZdXq
        display: flex;
        flex-direction: column;
        align-items: center;

        .team-trophy {
          width: 60px;
          height: 60px;
          margin-bottom: 10px;
        }

        .title {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 10px;
          gap: 30px;

          h3 {
            font-size: 25px;
          }
        }

        p {
          text-align: center;
        }
      }
    }


    .download-photos {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 5px;
      border: 1px solid #FFFFFF;
      width: 210px;
      padding: 8px 0px;
      border-radius: 5px;

      a {
        font-weight: 500;
        padding-top: 2px;
        text-decoration: none;
        color: white;
      }
    }


    .team-photo-uploads {
      display: flex;
      flex-direction: column;

      .team-item {
        background-color: white;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); //https://codepen.io/sdthornton/pen/wBZdXq
        margin-bottom: 40px;
        border-radius: 30px;
        padding: 20px 40px;

        .team-info {
          margin-bottom: 20px;

          .team-name {
            text-align: center;
            font-size: 2em;
            font-weight: bold;
            // font-family: 'Gagalin';
            margin-right: 10px;
          }
        }


        .uploads {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 28px;

          .image-wrapper {
            border: 1px solid #CCCCCC;
            border-radius: 10px;

            .private-image {
              border-radius: 10px 10px 0px 0px;
            }

            .image-info {
              padding: 15px;
              width: 263px;
            }
          }
        }
      }
    }

    .open-questions-wrapper {
      .open-questions-list {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;

        .open-question-item {
          background-color: #FFFFFF;
          padding: 15px;
          width: 300px;
          border-radius: 20px;

          .open-question-supertitle {
            font-size: 14px;
            text-align: center;
          }

          .open-question-title {
            font-size: 20px;
            text-align: center;
            font-weight: bold;
          }

          .open-question-replies {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;

            .open-question-reply {
              width: 60%;
              padding: 8px 20px;
              text-align: center;
              border-radius: 10px;
              border-width: 1px;
              border-style: solid;

              .reply-text {
                font-weight: bold;
                color: #3E3E3E;
                margin-bottom: 4px;
              }

              .reply-team {
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    .brought-to-you-by {
      width: 180px;
      background-color: white;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); //https://codepen.io/sdthornton/pen/wBZdXq
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 20px;
      margin-bottom: 30px;
    }


  }

  @media only screen and (max-width: 60em) {
    .wrapper {
      width: 100%;
      margin: 0px;

      >div {
        width: 90%;
      }

      .section-title {
        margin: 0px 30px;
      }

      .header {
        width: auto;
        flex-direction: column-reverse;

        .title-wrapper {
          text-align: center;
        }
      }

      .team-photo-uploads {
        .team-item {
          .uploads {
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }
  }
}