.event-list {
  padding: 10px;

  .event-list-item {
    margin: 10px 0px;

    .event-list-item-header {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .language-picker {
        margin-right: 10px;
      }
    }

    .event-list-item-screens {
      padding: 10px;
    }

    .event-list-item-controls {
      background-color: #f2f2f2;
      padding: 20px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .event-settings-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;

        p {
          margin: 0px;
        }
      }

      .event-list-item-controls-right>* {
        margin-left: 10px;
      }
    }
  }
}

.event-duration-control {
  height: 50px;
  display: flex;
  align-items: center;
}

.event-settings-dialog {
  .event-settings-timings {
    margin: 10px 0px 0px 0px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    background-color: #f2f2f2;
    border-radius: 10px;

    .rdtPicker {
      position: relative;
      width: 250px;
    }
  }
}